import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { WebSocketMessage } from 'src/app/shared/models/WebSocketMessage';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LiveService } from 'src/app/shared/services/live.service';
import { MedinboxService } from 'src/app/shared/services/medinbox.service';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { UserService } from 'src/app/shared/services/user.service';
import { WebSocketClientService } from 'src/app/shared/services/web-socket-client.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AnimationOptions } from 'ngx-lottie';
import { WebSocketActionEnum } from 'src/app/shared/models/enums/WebsocketActions';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-loading-medinbox',
  templateUrl: './loading-medinbox.component.html',
  styleUrls: ['./loading-medinbox.component.scss'],
})
export class LoadingMedinboxComponent implements OnInit, OnDestroy, AfterViewInit {
  options: AnimationOptions = {
    path: '/assets/coeur.json',
  };
  public loading: boolean = true;

  private subscriptions: Subscription[] = [];
  private medinboxEquipmentId: string | undefined;

  constructor(
    private webSocketService: WebSocketClientService,
    private userService: UserService,
    private router: Router,
    private liveService: LiveService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private organisationService: OrganisationService,
    private medinboxService: MedinboxService,
  ) {}

  ngOnInit(): void {
    const routeSub = this.activatedRoute.queryParams.subscribe(async (params) => {
      if (params.tmp) {
        this.medinboxEquipmentId = params.medinboxId;
        this.medinboxService.medinboxId$.next(this.medinboxEquipmentId as string);
        if (this.medinboxEquipmentId) {
          localStorage.setItem('currentEquipment', this.medinboxEquipmentId);
        }
        if (!(await this.userService.isAuthenticated())) {
          // TODO : the tmp param should be an hash of a one time password
          const decryptedPassword = Buffer.from(params.tmp, 'base64').toString();
          await this.authService.signingIn({ username: `${this.medinboxEquipmentId}@medinbox.com`, password: decryptedPassword });
        }
        this.userService.refreshCurrentUserData();
      }
    });
    const subMessage = this.webSocketService.medinboxMessage$.subscribe((message) => {
      if (message && message.audience == 'EQUIPMENT') {
        if (message.message.action == WebSocketActionEnum.CONFIGURATION_LOADED) {
          this.loading = false;
          this.medinboxService.medinboxConfiguration$.next(message.message.params);
          if (this.userService.user$.getValue()) {
            this.router.navigate(['medinbox', 'home']);
          }
        }
        if (message.message.action == WebSocketActionEnum.CONFIGURATION_CURRENT && !this.medinboxService.currentLayout$.getValue()) {
          this.medinboxService.currentLayout$.next(message.message.params);
          this.medinboxService.previousLayout$.next(this.medinboxService.currentLayout$.getValue()?.Id ?? 0);
        }

        if (message.message.action == WebSocketActionEnum.PING && this.medinboxEquipmentId) {
          this.webSocketService.sendMedinboxMessage({
            audience: 'EQUIPMENT',
            sourceId: this.userService.getCurrentUserId(),
            message: { action: WebSocketActionEnum.PONG },
            destinationId: this.medinboxEquipmentId,
            liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
          } as WebSocketMessage);
        }
      }
    });
    const userSub = this.userService.user$
      .pipe(
        filter((user) => user != null),
        take(1),
        map(() => {
          this.organisationService.refreshUserOrganisation();
        }),
        catchError((err: any) => {
          console.log(err);
          this.messageService.showError(err.message);
          return of(null);
        }),
      )
      .subscribe();
    const orgSub = this.organisationService.currentOrganisations$
      .pipe(
        filter((org) => org != null),
        take(1),
        map(async () => {
          await this.webSocketService.initMedinboxChannel(this.medinboxEquipmentId as string);
          this.webSocketService.sendMedinboxMessage({
            audience: 'EQUIPMENT',
            sourceId: this.userService.getCurrentUserId(),
            message: { action: WebSocketActionEnum.CHROME_STARTED },
            destinationId: this.medinboxEquipmentId,
            liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
          } as WebSocketMessage);
          if (!this.loading) {
            this.router.navigate(['']);
          }
        }),
      )
      .subscribe();

    this.subscriptions.push(routeSub, userSub, orgSub, subMessage);
  }

  ngAfterViewInit(): void {
    if (this.loaderService.status()) {
      this.loaderService.hide();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
