import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
import { Latency } from '../models/Latency';
import { WebSocketMessage } from '../models/WebSocketMessage';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { WebSocketActionEnum } from '../models/enums/WebsocketActions';

@Injectable({
  providedIn: 'root',
})
export class WebSocketClientService {
  public message$: BehaviorSubject<WebSocketMessage | null> = new BehaviorSubject<WebSocketMessage | null>(null);
  public userMessage$: BehaviorSubject<WebSocketMessage | null> = new BehaviorSubject<WebSocketMessage | null>(null);
  public drawingMessage$: BehaviorSubject<WebSocketMessage | null> = new BehaviorSubject<WebSocketMessage | null>(null);
  public chatMessage$: BehaviorSubject<WebSocketMessage | null> = new BehaviorSubject<WebSocketMessage | null>(null);
  public latency$: BehaviorSubject<Latency> = new BehaviorSubject<Latency>({ latency: 0, canSendMessage: true });
  public lastPongDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  public warning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public medinboxMessage$: BehaviorSubject<WebSocketMessage | null> = new BehaviorSubject<WebSocketMessage | null>(null);
  private jwt: string = '';
  public socketMedinbox: any = null;
  public generalSocket: any = null;
  public latencySocket: any = null;

  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) {}

  public async initMedinboxChannel(medinboxEquipmentId: string, allowNotMedinboxUser: boolean = false) {
    if (!this.socketMedinbox && (await this.userService.isAuthenticated()) && (allowNotMedinboxUser || (await this.userService.isMedinboxEquipment()))) {
      this.jwt = (await this.authService.getIdToken()) || '';
      const channel = `MedinboxEquipment${(await this.userService.isMedinboxEquipment()) ? 'Private' : 'Control'}-${medinboxEquipmentId}`;
      this.socketMedinbox = io(`${environment.webSocketUrl}`, {
        query: {
          channels: channel,
          jwt: this.jwt,
        },
      });
      this.subscribeToMedinboxChannels(channel);

      // handle connect_error
      this.socketMedinbox.on('connect_error', async (err: any) => {
        console.log(err);
        this.jwt = (await this.authService.getIdToken()) || '';

        const channel = `MedinboxEquipment${(await this.userService.isMedinboxEquipment()) ? 'Private' : 'Control'}-${medinboxEquipmentId}`;
        this.socketMedinbox = io(`${environment.webSocketUrl}`, {
          query: {
            channels: channel,
            jwt: this.jwt,
          },
        });
      });
    }
  }

  private subscribeToMedinboxChannels(channel: string | null) {
    this.socketMedinbox.on(channel, (message: any[]) => {
      if (Array.isArray(message)) {
        this.medinboxMessage$.next(message[0] as WebSocketMessage);
      } else {
        this.medinboxMessage$.next(message as WebSocketMessage);
      }
    });
  }

  public async initWebSocketService(liveReference: string) {
    if (await this.userService.isAuthenticated()) {
      this.jwt = (await this.authService.getIdToken()) || '';
      if (!this.latencySocket) {
        this.initLatencySocket(liveReference);
      }
      if (!this.generalSocket) {
        this.generalSocket = io(`${environment.webSocketUrl}`, {
          query: {
            channels: `BackendControlLive-${liveReference},UserControlLive-${liveReference},DrawLive-${liveReference},ChatLive-${liveReference},UserToUser-${Md5.hashStr(
              this.userService.getCurrentUserId(),
            )}`,
            jwt: this.jwt,
            live: liveReference,
          },
        });
        this.subscribeToGeneralsChannels();

        //handles connect_error
        this.generalSocket.on('connect_error', async (err: any) => {
          console.log(err);
          this.jwt = (await this.authService.getIdToken()) || '';
          this.generalSocket = io(`${environment.webSocketUrl}`, {
            query: {
              channels: `BackendControlLive-${liveReference},UserControlLive-${liveReference},DrawLive-${liveReference},ChatLive-${liveReference},UserToUser-${Md5.hashStr(
                this.userService.getCurrentUserId(),
              )}`,
              jwt: this.jwt,
              live: liveReference,
            },
          });
        });
      }
    }
  }

  private subscribeToGeneralsChannels() {
    this.generalSocket.onAny((channel: string, message: any[]) => {
      if (message) {
        const wsMessage = (Array.isArray(message) ? message[0] : message) as WebSocketMessage;
        if (channel.indexOf('DrawLive') != -1) {
          this.drawingMessage$.next(wsMessage);
        } else if (channel.indexOf('UserToUser') != -1) {
          this.userMessage$.next(wsMessage);
        } else if (channel.indexOf(`ChatLive`) != -1) {
          this.chatMessage$.next(wsMessage);
        } else {
          this.message$.next(wsMessage);
        }
      }
    });
  }

  private initLatencySocket(currentLiveReference: string) {
    this.latencySocket = io(`${environment.webSocketUrl}`, {
      query: {
        channels: `Latency`,
        jwt: this.jwt,
      },
    });
    this.subscribeToLatencyChannel(currentLiveReference);
    this.latencySocket.on('connect_error', async (error: any) => {
      console.log(error);
      this.jwt = (await this.authService.getIdToken()) || '';
      this.latencySocket = io(`${environment.webSocketUrl}`, {
        query: {
          channels: `Latency`,
          jwt: this.jwt,
        },
      });
    });
  }

  private subscribeToLatencyChannel(currentLiveReference: string) {
    this.latencySocket.on(`Latency`, (message: any) => {
      if (message) {
        const pingMessage = message as WebSocketMessage;
        if (pingMessage.message.action == WebSocketActionEnum.PING) {
          this.latency$.next({ latency: pingMessage.message.params.latency, canSendMessage: pingMessage.message.params.canSendMessage });
          this.latencySocket.emit(`Latency`, {
            audience: 'WEBSOCKETSERVER',
            message: {
              action: WebSocketActionEnum.PONG,
            },
            sourceId: this.userService.getCurrentUserId(),
            liveReference: currentLiveReference,
            destinationId: 'WEBSOCKETSERVER',
          } as WebSocketMessage);
          this.lastPongDate$.next(new Date());
        }
      }
    });
  }

  public async sendMessage(message: WebSocketMessage) {
    if (this.generalSocket) {
      this.generalSocket.emit(`UserControlLive-${message.destinationId}`, message);
    }
  }

  public async sendDrawingMessage(message: WebSocketMessage) {
    if (this.generalSocket) {
      this.generalSocket.emit(`DrawLive-${message.destinationId}`, message);
    }
  }

  public async SendChatMessage(message: WebSocketMessage) {
    if (this.generalSocket) {
      this.generalSocket.emit(`ChatLive-${message.destinationId}`, message);
    }
  }

  public async sendUserMessage(message: WebSocketMessage) {
    if (this.generalSocket) {
      this.generalSocket.emit(`UserToUser-${Md5.hashStr(message.destinationId)}`, message);
    }
  }

  public async sendMedinboxMessage(message: WebSocketMessage) {
    if (!this.socketMedinbox) {
      await this.initMedinboxChannel(message.destinationId);
    }
    this.socketMedinbox.emit(`MedinboxEquipment${(await this.userService.isMedinboxEquipment()) ? 'Private' : 'Control'}-${message.destinationId}`, message);
  }

  public async closeWebSockets() {
    if (this.socketMedinbox && !(await this.userService.isMedinboxEquipment())) {
      await this.socketMedinbox.disconnect();
      this.socketMedinbox = null;
      this.medinboxMessage$.next(null);
    }
    if (this.latencySocket) {
      await this.latencySocket.disconnect();
      this.latencySocket = null;
      this.latency$.next({ latency: 0, canSendMessage: true });
    }
    if (this.generalSocket) {
      await this.generalSocket.disconnect();
      this.userMessage$.next(null);
      this.message$.next(null);
      this.drawingMessage$.next(null);
      this.generalSocket = null;
    }
  }
}
