import { VonageConferenceService } from 'src/app/shared/services/vonage-session.service';

export const environment = {
  production: false,
  apiUrl: 'https://api.dev.aws.medinbox.link',
  loggingUrl: 'https://logging.dev.aws.medinbox.link',
  webSocketUrl: 'https://websocket.dev.aws.medinbox.link',
  env: 'Dev',
  cognito: {
    userPoolId: 'eu-west-1_IJ9NsZtHN',
    userPoolWebClientId: '7t5jtgq0gi1c9348enr33399ng',
  },
  communicationService: VonageConferenceService,
};
