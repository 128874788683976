import { Component } from '@angular/core';
import { LoaderService } from './shared/services/loader.service';
import { UtilsService } from './shared/services/utils.service';
import { AnimationOptions } from 'ngx-lottie';
import packageJson from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public currentAnimation: AnimationOptions = this.utilsService.getAnimations();

  public loadAnimation: AnimationOptions = {
    path: '/assets/loader.json',
  };

  public version: string = packageJson.version;

  constructor(
    public loaderService: LoaderService,
    private utilsService: UtilsService,
  ) {
    this.utilsService.registerIcons();
  }
}
