import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SessionOption } from 'src/app/shared/models/SessionOption';
import { FormErrorHandler } from 'src/app/shared/utils/FormErrorHandler';
import { UtilsService } from '../../shared/services/utils.service';
import { Router } from '@angular/router';
import { DisclaimerDialogData } from 'src/app/shared/models/DisclaimerDialogData';
import { DisclaimerDialogComponent } from 'src/app/shared/components/disclaimer-dialog/disclaimer-dialog.component';
import { filter, firstValueFrom, lastValueFrom, map, take } from 'rxjs';
import { WebSocketMessage } from 'src/app/shared/models/WebSocketMessage';
import { CreateLiveDto } from 'src/app/shared/models/CreateLiveDto';
import { InvitedPeopleDto } from 'src/app/shared/models/InvitePeopleDto';
import { MatDialog } from '@angular/material/dialog';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { UserService } from 'src/app/shared/services/user.service';
import { LiveService } from 'src/app/shared/services/live.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { WebSocketClientService } from 'src/app/shared/services/web-socket-client.service';
import { MedinboxService } from 'src/app/shared/services/medinbox.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { LiveType } from 'src/app/shared/models/enums/LiveType';
import { RoleEnum } from 'src/app/shared/models/enums/LiveRole';
import { WebSocketActionEnum } from 'src/app/shared/models/enums/WebsocketActions';
import { ICommunicationService } from 'src/app/shared/services/communication.service';

@Component({
  selector: 'app-start-live',
  templateUrl: './start-live.component.html',
  styleUrls: ['./start-live.component.scss'],
})
export class StartLiveComponent implements OnInit, AfterViewInit {
  public sessionOption: SessionOption = {
    chatRoomEnabled: false,
    recordSession: false,
    secureWithPIN: false,
    permanentLive: false,
  };
  form: UntypedFormGroup = this.fb.group({
    liveName: ['', [Validators.required, Validators.minLength(4), this.errorHandler.noWhitespaceValidator]],
    cohostEmail: ['', [this.errorHandler.multipleEmailValidator]],
    owner: ['', [Validators.email, this.errorHandler.emailValidator]],
    description: [''],
    contact: ['', [this.errorHandler.multipleEmailValidator]],
  });
  moderatorInvites: string[] = [];
  contactInvites: string[] = [];
  private generatedSessionName: string = '';
  errors: any = {};
  isGenerate: boolean = true;
  isDetails: boolean = false;
  origin = window.location.origin;
  sessionUrl = '';

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private utilsService: UtilsService,
    private errorHandler: FormErrorHandler,
    private fb: UntypedFormBuilder,
    private organisationService: OrganisationService,
    private liveService: LiveService,
    @Inject('ICommunicationService') private communicationService: ICommunicationService,

    private loader: LoaderService,
    private translate: TranslateService,
    private webSocketClient: WebSocketClientService,
    private medinboxService: MedinboxService,
    private errorService: MessageService,
    public userService: UserService,
    public utils: UtilsService,
  ) {
    this.generatedSessionName = this.utilsService.genereateConferenceName();
    this.sessionUrl = `${window.location.origin}/${this.generatedSessionName}`;
    this.form.get('liveName')?.setValue(this.generatedSessionName);
  }

  async ngOnInit(): Promise<void> {
    this.form.valueChanges.subscribe((val) => {
      this.errors = {};
      if (val.liveName) {
        this.sessionUrl = `${window.location.origin}/${val.liveName}`;
      }
      this.errorHandler.handleErrors(this.form, this.errors);
    });

    if (!this.organisationService.currentOrganisations$.getValue()) {
      this.organisationService.refreshUserOrganisation();
    }
    this.organisationService.currentOrganisations$
      .pipe(
        filter((o) => o != null),
        take(1),
        map(async () => {
          await this.userService.refreshCurrentUserData();
          if (
            !this.webSocketClient.socketMedinbox &&
            (await this.userService.isMedinboxEquipment()) &&
            (this.medinboxService.medinboxId$.getValue() || localStorage.getItem('equipmentId'))
          ) {
            let currentEquipmentId = this.medinboxService.medinboxId$.getValue();
            if (!currentEquipmentId) {
              currentEquipmentId = localStorage.getItem('equipmentId');
              this.medinboxService.medinboxId$.next(currentEquipmentId);
            }
            if (currentEquipmentId) {
              this.webSocketClient.initMedinboxChannel(currentEquipmentId);
            }
          }
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.loader.status()) {
      this.loader.hide();
    }
  }

  addModerator(val: string): void {
    if (!this.form.controls.cohostEmail.invalid && val) {
      const inputEmails = this.form.get('cohostEmail')?.value ?? '';
      const emails = inputEmails.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      emails.forEach((newEmail: string) => {
        if (this.moderatorInvites.findIndex((s) => s.toLowerCase() == newEmail.toLowerCase() && this.form.get('owner')?.value != newEmail) == -1) {
          this.moderatorInvites.push(newEmail);
          const existingIndexInInvites = this.contactInvites.indexOf(val);
          if (existingIndexInInvites != -1) {
            this.contactInvites.splice(existingIndexInInvites, 1);
          }
        }
      });
      this.form.get('cohostEmail')?.setValue('');
    }
  }

  addContact(val: string): void {
    if (!this.form.controls.contact.invalid && val) {
      const inputEmails = this.form.get('contact')?.value ?? '';
      const emails = inputEmails.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      emails.forEach((newEmail: string) => {
        if (this.contactInvites.findIndex((s) => s.toLowerCase() == newEmail.toLowerCase() && this.form.get('owner')?.value != newEmail) == -1) {
          this.contactInvites.push(newEmail);
          const existingIndexInModerators = this.moderatorInvites.indexOf(val);
          if (existingIndexInModerators != -1) {
            this.moderatorInvites.splice(existingIndexInModerators, 1);
          }
        }
      });
      this.form.get('contact')?.setValue('');
    }
  }

  remove(val: string, invites: string[]): void {
    const index = invites.indexOf(val);

    if (index >= 0) {
      invites.splice(index, 1);
    }
  }

  handleConfName() {
    if (!this.form.get('liveName')?.value) {
      this.isGenerate = false;
      this.generatedSessionName = this.utilsService.genereateConferenceName();
      this.form.get('liveName')?.setValue(this.generatedSessionName);
      this.form.get('url')?.setValue(`${window.location.origin}/${this.generatedSessionName}`);
    }
  }

  handleFocus() {
    if (this.isGenerate) {
      this.form.get('liveName')?.setValue('');
    }
  }

  async createALive() {
    if (await this.disclaimer()) {
      const confName = this.form.get('liveName')?.value || this.generatedSessionName;
      const ownerEmail = this.form.get('owner')?.value || (this.userService.user$.getValue()?.email as string);
      let invitedPeoples: InvitedPeopleDto[] = [];
      const inFieldModeratorsEmail = this.form.get('cohostEmail')?.value;
      const moderatorsEmails = inFieldModeratorsEmail.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      this.moderatorInvites = [...this.moderatorInvites, ...moderatorsEmails];
      if (this.moderatorInvites.length > 0) {
        this.moderatorInvites.forEach((element) => {
          if (element != ownerEmail)
            invitedPeoples.push({
              email: element,
              role: RoleEnum.Moderator,
            } as InvitedPeopleDto);
        });
      }
      const inFieldContactsEmail = this.form.get('contact')?.value;
      const contactsEmails = inFieldContactsEmail.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      this.contactInvites = [...this.contactInvites, ...contactsEmails];
      if (this.contactInvites.length > 0) {
        this.contactInvites.forEach((element) => {
          if (element != ownerEmail)
            invitedPeoples.push({
              email: element,
            });
        });
      }

      const createLive = {
        organizationId: this.organisationService.currentOrganisations$.getValue()?.organizationId as number,
        reference: this.generatedSessionName,
        name: confName,
        ownerEmail: ownerEmail,
        isReservedAlias: false,
        description: this.form.get('description')?.value,
        equipmentId: this.medinboxService.medinboxId$.getValue() ?? ownerEmail.substring(0, ownerEmail.indexOf('@')),
        liveType: LiveType.Public,
        invitedPeoples: invitedPeoples,
      } as CreateLiveDto;
      this.loader.display();
      try {
        await this.startLive(createLive, invitedPeoples);
      } catch (err) {
        this.loader.hide();
      }
    }
  }

  private async startLive(createLive: CreateLiveDto, invitedPeoples: InvitedPeopleDto[]) {
    const live = await lastValueFrom(this.liveService.createLive(createLive));
    const liveStarted = await lastValueFrom(this.communicationService.startConference(live.reference));
    this.liveService.currentLive$.next(liveStarted);
    localStorage.setItem('currentLive', JSON.stringify(liveStarted));
    await this.webSocketClient.initWebSocketService(liveStarted.reference);
    await this.communicationService.startSession();

    const started = await this.communicationService.joinConference(liveStarted.vonageSessionId as string);
    if (started != 'OK') {
      this.loader.hide();
      if (started == 'Permission denied in browser') {
        this.errorService.showError(
          `<span>Your browser can't access the required devices</span>
              <br/>
              <br/>
              <span>If you need help to allow device access, you can have a look 
              <a href="/assets/HowTo/how-to-allow-device-access.html" target="_blank">here</a>.</span>
              <br/>
              <br/>
              <span>If the problem persists please contact Medinbox support <br/> at <a href=\"mailto:support@medinbox.com\">support@medinbox.com</a></span>`,
        );
      } else {
        this.errorService.showError(
          `<span>${started}</span><br/><span>If the problem persists please contact Medinbox support <br/> at <a href=\"mailto:support@medinbox.com\">support@medinbox.com</a></span>`,
        );
      }
      this.communicationService.leaveSession(true);
      await lastValueFrom(this.liveService.endLive(live.reference));
      await this.liveService.leaveLive();
      this.router.navigate([]);
    } else if (await this.userService.isMedinboxEquipment()) {
      lastValueFrom(
        this.liveService.sendEmailInvitation({
          liveReference: this.liveService.currentLive$.getValue()?.reference ?? 'no-live-found',
          invitedPeoples: invitedPeoples,
        }),
      );
      await this.webSocketClient.sendMessage({
        audience: 'LIVE',
        sourceId: this.userService.getCurrentUserId(),
        message: { action: WebSocketActionEnum.STARTED, params: { LiveReference: live.reference, LiveId: live.liveId } },
        destinationId: this.liveService.currentLive$.getValue()?.reference,
      } as WebSocketMessage);
      await this.webSocketClient.sendMedinboxMessage({
        message: { action: WebSocketActionEnum.STARTED, params: { LiveReference: live.reference, LiveId: live.liveId } },
        audience: 'EQUIPMENT',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.medinboxService.medinboxId$.getValue(),
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
      } as WebSocketMessage);
      this.router.navigate(['live'], {
        queryParams: {
          live: this.liveService.currentLive$.getValue()?.reference,
          name: this.liveService.currentLive$.getValue()?.name,
          conferenceId: this.communicationService.currentSession$.getValue().sessionId,
        },
      });
    }
  }

  private async disclaimer(): Promise<boolean> {
    const dialogRef = this.dialog.open(DisclaimerDialogComponent, {
      minWidth: '300px',
      maxWidth: '500px',
      data: {
        yesNoDialog: true,
        title: this.translate.instant('LIVE.JOIN_LIVE.EQUIPMENT_DISCLAIMER_TITLE'),
        text: this.translate.instant('LIVE.JOIN_LIVE.EQUIPMENT_DISCLAIMER_TEXT'),
        yesNoButton: false,
      } as DisclaimerDialogData,
      panelClass: ['disclaimer-modal', 'disclaimer-modal-full-height'],
    });

    return (await firstValueFrom(dialogRef.afterClosed())) ?? false;
  }
}
